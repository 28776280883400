.ghostwhite {
   background-color: ghostwhite;
}

.dark {
    background-color: #007bff;
    color: #ffffff;
}

.dark-hover:hover {
    background-color: rgb(27, 76, 130);
    color: #ffffff;
}

.banner {
    flex: 1;
    width: 100%;
    margin-bottom: 25px;
}

.device-health-component {
    min-height: 100vh;
}

.device-health-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.device-health-table-footer {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    height: 10px;
    margin-bottom: 2rem;
}
.device-health-table-header {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    height: 20px;    
}

.device-health-item-btn {
    border: none;
    background: none;
    text-decoration: underline;
    color: #007bff;
}
