body {
    color: black;
}

/* 
    Workaround to disable the default stack trace overlay,
    which is only enabled in local dev because of react-scripts (no opt-out).
    Without this line, the overlay will appear in local dev
    regardless of properly working Error Boundaries.
*/
body > iframe { display: none }

#site-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2.65rem;
    z-index: 10;
}

#site-header > table {
    width: 100%;
}

.site-title {
    padding-left: 10px;
    float: left;
}

.site-title a:hover {
    text-decoration: none;
}

.site-header-auth-nav {
    float: right;
}

#site-main {
    display: flex;
    flex-direction: row;
    padding-top: 2.62rem;
}

.icon {
    vertical-align: middle;
    align-content: center;
    font-size: 9px;
    height: 100px;
    width: 100px;
    right: 10px;
    bottom: 15px;
}

.listcontainer {
    display: flex;
    overflow-x: hidden;
    width: 100%;
}

.card {
    flex-shrink: 0;
    width: 100%;
    height: 200px;
    border-radius: 10px;
    margin-left: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: rgb(24, 89, 155);
    color: white;
    text-align: center;
    justify-content: center;
}

@import url('https://fonts.googleapis.com/css?family=Quicksand');

a {
    text-decoration: none;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.companyLogo {
    width: 140px;
    padding-right: 20px;
}

/*====== WIDTH ======*/
.title {
    color: rgb(24, 89, 155);
    font-family: 'Calibri';
}
.header {
    color: dimgray;
    font-family: 'Calibri';
}
.buttonlink {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

.screenW {
    width: 100vw;
}

.fullW {
    width: 100%;
}
/*====== HEIGHT ======*/
.paddingBottom24 {
    padding-bottom: 24px;
}

.fullH {
    height: 100%;
}
.requiredField {
    color: red;
}

.height70 {
    height: 70px;
}
/*====== BG COLOR ======*/
.dGray {
    background-color: #333;
}

.white {
    background-color: #fff;
}

.white50 {
    background-color: gray;
}

/*====== FONT ======*/
.fSize2 {
    font-size: 2rem;
}
/*====== IMAGE ======*/
.imgCover {
    object-fit: cover;
}
/*====== OVERFLOW ======*/
.overflowH {
    overflow: hidden;
}
/*====== TRANSITIONS ======*/
.transition1l {
    transition: left 1s ease-in-out;
}

/*====== POSITION ======*/
.abs {
    position: absolute;
}

.rel {
    position: relative;
}

.onScreen {
    left: 0px;
    right: 0px;
}

.left100vw {
    left: 100vw;
}

.leftM100vw {
    left: -100vw;
}

.bot0 {
    bottom: 0;
}
/*====== FLEX ======*/
.df {
    display: flex;
}

.row {
    display: flex;
    flex-direction: row;
}

.col {
    display: flex;
    flex-direction: column;
}

.fCenter {
    display: flex;
    justify-content: center;
    @import '../src/scss/site';
    align-items: center;
}

.flex1 {
    flex: 1;
}

.flex8 {
    flex: 8;
}

/*====== CUSTOM ======*/
.dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    transition: background-color 1s ease-in-out;
}

.buttonSD-primary {
    background-color: rgb(24, 89, 155);
    color: #ffffff;
}

.buttonSD-secondary {
    background-color: #ffffff;
    border: 1px solid black;
    color: #000000;
}