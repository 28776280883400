.wrapper {
    margin: auto;
    background: #fff;
    display: flex;
    flex-direction: column;
    font-family: 'Calibri';
}

.banner {
    width: 100%;
    height: auto;
}

.securedevices-unauthorized {
    color: #4c4cff;
}

.securedevicesform {
    background-color: white;
    min-height: 100px;
    border-radius: 5px;
    width: 560px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: 'Calibri';
}

.securedevicesform-title {
    font-size: 20px !important;
    padding: 15px 10px !important;
}

.securedevicesform-body {
    text-align: left;
    padding: 15px 10px;
}

.securedevices-label {
    width: 40% !important;
    float: left !important;
    clear: right !important;
    padding: 15px !important;
}

.securedevices-alert-msg {
    width: 100% !important;
    float: left !important;
    clear: right !important;
    padding: 15px !important;
}

.securedevices-input {
    width: 60% !important;
    float: right !important;
    padding: 15px !important;
    font-size: 1rem;
}

.securedevices-dropdown {
    width: 60% !important;
    float: right !important;
    padding: 15px !important;
}

.crop-dropdown .ms-Dropdown-callout {
    height: 200px;
}

.securedevices-error {
    width: 60%;
    line-height: 15px;
    float: right;
    padding: 5px 15px !important;
}

.securedevices-checkbox {
    float: left;
    padding: 15px 15px 5px;
    font-size: 10px;
}

.securedevices-warning {
    padding: 10px;
}

.securedevicesform-footer {
    float: left;
    padding: 15px 15px;
}