.standard-form {
}

.vertical {
    display: flex;
    flex-direction: column;
}

.standard-form-subheader {
    font-size: large;
    font-weight: normal;
}

.standard-form-item {
    margin: 1rem 0;
}

.standarm-form-button {
    width: 200px;
    padding: 1rem;
}

.standard-form-label {
    margin: 1rem 0;
    font-weight: bold;
}

.standard-form-textfield {
    width: 100%;
}

.textfield {
    border: 1px solid gray;
}

/* goes in */
.filter-search {
    border: 1px solid gray;
    margin-right: 1rem;
}