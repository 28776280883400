.standard-list-item-count {
    padding-top: 0.5rem;
}

.command-bar-container {
    margin-bottom: 20px;
    padding: 0;
}

.command-bar {
    padding-left: 0;
    margin-bottom: 0px;
    border-bottom: 1px solid #ddd;
}

.command-bar .ms-CommandBar {
    padding-left: 0;
}

.standard-list-datagrid-container {
    position: relative;
    min-width: 1200px;
}

.standard-list-datagrid-header {
    font-weight:500;
}

.standard-list-loading-overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.9;
}