.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10; /* ensure it appears above other content */
    background-color: rgba(0, 0, 0, 0.5);
}

.loading-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* center the image */
}
