.standard-page-title {
    border-bottom: 1px solid #cfcfcf;
}

.standard-page-title > h1 {
    margin: 0 3rem;
    padding: 1.5rem 0;
}

.standard-page-content {
    margin: 0.5rem 2rem 0 2rem;
    z-index: -1;
}

.standard-page-announcement {
    padding: 0.5rem 1rem;
}

.standard-page-error {
    width: 60%;
    margin: 3rem auto;
    text-align: center;
}