.iconrefreshuserlogin {
    height: 20px;
    background: url('../../../Images/RefreshUserLoginIcon.png');
    background-size: cover;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 5px;
}

.root {
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }

.user-list-error {
    width: 100%;
    padding: 1rem 2rem;
    background-color: #ff9c9c;
}